<template>
  <en-drawer :model-value="modelValue" width="80%" title="批量设置收费类别" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item label="派工班组" prop="workingTeam.id">
        <select-maintain
          v-model="form.data.workingTeam"
          :ajax="{
            action: 'GET /enocloud/common/workingteam',
            params: (params) => (params.payload = { branchIds: [Number(branchId)], statusCode: 'A' })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          @change="form.workingTeam.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>

      <en-form-item label="技师">
        <select-maintain
          :model-value="form.data.assignees.map((item) => item.assignee || item.name)"
          :options="form.data.workingTeam?.members"
          :props="{ label: 'user.name', value: 'user' }"
          allow-create
          multiple
          value-key="id"
          collapse-tags
          collapse-tags-tooltip
          class="w-full"
          @change="form.assignees.change"
        ></select-maintain>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
interface Assignee {
  id?: number
  assignee?: EnocloudCommonDefinitions['UserDto']
  name?: string
}

export default factory({
  props: {
    modelValue: Boolean,
    branchId: Number
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value1: EnocloudServiceDefinitions['WorkingTeamDto'], value2: Array<Assignee>) =>
      typeof value1 === 'object' && typeof value2 === 'object'
  },

  config: {
    children: {
      form: {
        data: {
          workingTeam: { members: [] },
          assignees: [] as Assignee[]
        },
        rules: {
          'workingTeam.id': [{ required: true, message: '请选择派工班组' }]
        },
        children: {
          workingTeam: {
            change() {}
          },
          assignees: {
            change(value: Array<string | EnocloudServiceDefinitions['UserDto']>) {
              this.form.data.assignees = value.map((item) =>
                typeof item === 'string'
                  ? { name: item }
                  : {
                      id: this.form.data.assignees.find((a) => a.assignee?.id === item.id)?.id,
                      name: this.form.data.assignees.find((a) => a.assignee?.id === item.id)?.name,
                      assignee: item
                    }
              ) as Assignee[]
            }
          }
        }
      },

      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            async click() {
              await this.refs.form.validate()
              this.emit('confirm', this.form.data.workingTeam, this.form.data.assignees)
              this.footer.cancel.click()
            }
          }
        }
      }
    }
  }
})
</script>
